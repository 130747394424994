import { React, useState, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Divide as Hamburger } from 'hamburger-react';
import logo from '../../../assets/logo/logo-full-sunburst-250x67.png';
import CTAButton from '../cta-button/CTAButton';
import './header.css';
import LighteningBolt1 from '../elements-icons/LighteningBolt1';

// Set routes that should be transparent on load
const transparentRoutes = ['/'];

const Header = () => {
  const [open, setOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  
  const handleToggle = () => {
    setOpen(false);
  }
  
  useEffect(() => {
    const checkScroll = () => {
      if (transparentRoutes.includes(location.pathname)) {
        setIsScrolled(window.scrollY <= 100);
      } else {
        setIsScrolled(true);
      }
    };
    
    // Check scroll position immediately when route changes
    checkScroll();
    
    // Attach the event listener
    window.addEventListener('scroll', checkScroll);
    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  }, [location.pathname]);

// className logic based on isScrolled and the current route
  let setHeaderClass = '';
  let setHeaderPadding = '';
  if (transparentRoutes.includes(location.pathname)) {
    if (isScrolled) {
      setHeaderClass = 'section-header-fixed header text-lt scrolled';
      setHeaderPadding += 'header-padding';
    } else {
      setHeaderClass = 'section-header-fixed header text-lt scrolled logo-scrolled';
      setHeaderPadding += 'header-padding';
    }
  }
  // ex. Dashboard
  else {
    setHeaderClass = 'section-header-fixed header text-lt bg-dk logo-scrolled';
    setHeaderPadding += 'header-padding-40';
  }
  
  return (
    <header className={`${setHeaderClass}`}>
      <div id="wrapper-header" className={`wrapper-header ${setHeaderPadding}`}>
        <div className='wrapper-header-logo'>
          <Link to="/">
            <img src={logo} alt='Go Shine Life logo' onClick={() => handleToggle()} className='logo'/>
          </Link>
        </div>
        <div className='wrapper-header-links'>
          <Hamburger
            toggled={open}
            toggle={setOpen}
            size={20}
            color='#f1f1f1'
            duration={1}
            className='menu-right'
            aria-label='hamburger-menu'
          />
          <nav className={`navigation nav-item ${open ? 'open' : 'close'}`}>
            <NavLink activeclassname="" to="/#meet-tethyr">
              <button className='btn-light text-lt btn-transparent'>
                Meet Tethyr
              </button>
            </NavLink>
            <NavLink activeclassname="" to="/#product-roadmap">
              <button className='btn-light text-lt btn-transparent' >
                Dev Roadmap
              </button>
            </NavLink>
            <NavLink activeclassname="" to="/#landing-contact">
              <button className='btn-light text-lt btn-transparent' >
                Contact Us
              </button>
            </NavLink>
          </nav>
        </div>
        <div className='wrapper-header-cta'>
          <CTAButton url='/#landing-contact'
                     className='btn-cta-sub bg-straight-gradient-green'
                     content='Sew A Seed'
                     icon={
            <LighteningBolt1
              svgClass='icon-md'
              pathClass=''
            />}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
